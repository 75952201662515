.main_library {
  .activity-added {
    width: 120px;
  }

  .dzu-dropzone {
    top: -78px;
    left: -8px;
    border: none;
    overflow: hidden;
    width: 90%;
  }

  .overview-select {
    width: 100%;
    height: 50px;
    border: 1px solid #ebebeb;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    padding: 15px;
  }

  .library-heading {
    padding-left: 15px;
    height: 22px;
    font-family: "Source Sans 3";
    font-style: normal;
    line-height: 22px;
    color: #fff;
    opacity: 0.9;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .library-paragraph {
    width: 100%;
    padding: 1px;
    height: 17px;
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    color: $black;
    text-align: center;
  }

  .library-footer {
    width: 12.5 rem;
    padding: 1px;
    height: 16px;
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1px;
    color: $black;
    opacity: 0.5;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  .library-search {
    width: 348px;
    height: 42px;
    border: 1px solid $gray-300;
    border-radius: 6px;
    margin: auto;
    text-align: center;
  }

  .height-card {
    height: auto;
    margin-top: 65px !important;
    margin-bottom: 20px;
    position: sticky;
    top: 0px;
  }

  .scroll-style {
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }
}

.host-sign {
  .form-check-input {
    border-radius: 50px;

    &.is-valid {
      background-color: #99c815 !important;
      border: none !important;
    }
  }
}

.host-page {
  .date-error {
    color: $danger;
    font-weight: 600;
    margin-top: -20px;
  }
}

.Timmer {
  margin-top: -35px;
  margin-left: 0px;

  .MuiOutlinedInput-root {
    height: 48px !important;
    width: 160px !important;
    border: none !important;
    background: #f7f7f7;
    border-radius: 6px;
  }
}

.host-offerings {
  ul.offerings {
    padding-top: 0.5rem !important;

    li {
      list-style: none;
      position: relative;
      background-image: url(../images/bullet-point.svg);
      background-repeat: no-repeat;
      background-size: 14px;
      background-position: left center;
      padding-top: 0.3rem !important;
      padding-bottom: 0 !important;
      max-width: 80% !important;

      span {
        &.onboarding {
          font-weight: 400;
          font-size: 18px;
          padding-top: 2rem !important;
          padding-bottom: 2rem !important;
          line-height: 30px;
          opacity: 0.8;
          color: $black;
        }
      }
    }
  }

  .main-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 0;
  }

  .main-para {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: $gray-1000;
  }

  .subscription-but {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #4a60ab;
    margin-right: 20px;
    text-decoration-color: #4a60ab;
    position: absolute;
    right: 0;
    bottom: 0.37rem;

    &.delete-account {
      position: static;
    }
  }

  .plan-date {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #555555 !important;
  }

  .current-plan {
    margin-left: 10px;
    width: 127px;
    height: 32px;
  }
}

.select-account-conatiner {
  .modal-content {
    border-radius: 14px;
    width: 420px;
  }
  .main-heading {
    font-size: 26px;
    font-weight: 600;
    line-height: 37px;
    letter-spacing: 0px;
    text-align: center;
    color: $primary;
    margin-top: 40px;
  }

  .card {
    width: 26.3rem;
    max-height: 30rem;
    padding: 18px;
    box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.5);
    border: none;
    margin: 1.8rem auto;
  }

  .card-top {
    background-color: #f5f5f5;
    border-radius: 8px;
    margin: 20px;
    padding-bottom: 15px;
  }

  .card-lower {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 25px;
    margin-top: 10px;
  }

  .top-button {
    background: $warning !important;
    padding: 8px 24px;
    height: 42px;
    border: none;

    &.default-btn {
      width: auto !important;
    }
  }

  .top-card-heading {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #121212;
    width: 65%;
  }

  .lower-heading {
    font-family: Source Sans 3;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 1px;
    text-align: left;
    margin-top: -10px;
  }

  .card-lower-button {
    position: absolute;
    bottom: 0px;
    margin-left: 5.32rem;
    margin-bottom: 40px;
  }

  .card-Play {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 12px;
    box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.05);
    margin-bottom: 32px;
  }

  .right-padding {
    padding-right: 100px;
  }

  .top-section {
    margin-top: 40px;
  }

  .how-pic {
    width: 440px;
    height: 230px;
  }

  .how-play-bt {
    width: 90px;
    background-color: $secondary;
    border: none;

    &:hover {
      background-color: $secondary !important;
    }
  }

  .button-sec {
    display: flex;
    justify-content: end;
    padding-bottom: 3rem;
    padding-top: 1rem;
  }
}

.contact {
  margin-top: 30px;

  .top-contact {
    color: $primary;
    margin-top: 20px;
  }

  .contact-email {
    color: $warning;
    opacity: 0.6;
  }
}

.last-sb-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #666666 !important;
  margin-bottom: 18px;
  margin-left: 3px;
}
