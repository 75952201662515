$enable-negative-margins: true;

$primary-font-family: "Source Sans 3", sans-serif;

$header-height: 74px;

$sidebar-width: 300px;
$base-font-size: 16px;
$root-font-size: 16;

$white: #fff !default;
$gray-50: #fbfbfb !default;
$gray-100: #f5f5f5 !default;
$gray-200: #eeeeee !default;
$gray-300: #e0e0e0 !default;
$gray-400: #bdbdbd !default;
$gray-500: #9e9e9e !default;
$gray-600: #888888 !default;
$gray-700: #616161 !default;
$gray-800: #4f4f4f !default;
$gray-850: #444444 !default;
$gray-900: #262626 !default;
$gray-1000: #121212 !default;
$black: #000 !default;

$primary: rgba(48, 157, 236, 1) !default;
$secondary: rgba(249, 159, 34, 1) !default;
$success: #00b74a !default;
$info: #39c0ed !default;
$warning: #f5ab38 !default;
$danger: #f93154 !default;
$card-border: #f9f9f9 !default;

$primary-bg-color: $gray-200;
$primary-font-color: $gray-900;
$check-label-color: #121212;

$box-shadow-none: none !default;
$box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.07) !default;

$box-shadow-1: 0 0px 5px 0 rgba(0, 0, 0, 0.05) !default;
$box-shadow-2: 0 0px 10px 0 rgba(0, 0, 0, 0.05) !default;
$box-shadow-3: 0 0px 15px 0 rgba(0, 0, 0, 0.05) !default;
$box-shadow-4: 0 0px 20px 0 rgba(0, 0, 0, 0.05) !default;
$box-shadow-5: 0 0px 30px 0 rgba(0, 0, 0, 0.05) !default;
$box-shadow-6: 0 0px 40px 0 rgba(0, 0, 0, 0.05) !default;
$box-shadow-card: 4px 6px 9px rgba(0, 0, 0, 0.03) !default;

$border-bottom: 1px solid rgba(0, 0, 0, 0.08);
$text-border: #ebebeb;

$border-radius: 6px;

// Break points
$smallScreen: 576px;
$mediumScreen: 768px;
$largeScreen: 992px;
$largeScreen-1: 1050px;
$xLargeScreen: 1200px;
$xxLargeScreen: 1400px;
