@import url("https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800&display=swap");
@import "~react-perfect-scrollbar/dist/css/styles.css";
@import "react-datepicker/dist/react-datepicker.css";
@import "./mixins/functions";
@import "./variables";
@import "./general";
@import "./typography";
@import "./inputs";
@import "./buttons";
@import "./card";
@import "./helper";
@import "./pages";
@import "./analytics";
@import "./library";

// @media (min-width: 1400px) {
//   .container,
//   .container-sm,
//   .container-md,
//   .container-lg,
//   .container-xl,
//   .container-xxl {
//     max-width: 100%;
//     padding-left: 65px !important;
//     padding-right: 65px !important;
//   }
// }

.container,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  max-width: 100%;
  padding-left: 65px !important;
  padding-right: 65px !important;
}

.btn-lg {
  min-width: 180px !important;
}
.account-btn {
  background-color: #4a60ab;
  min-width: 148px;
  height: 52px;
  border-radius: 60px;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #ffffff;
  border: none !important;
  &:hover,
  &:focus {
    background-color: #4a60ab !important;
    color: #ffffff;
    border: none !important;
  }
}
.account-cancel {
  background: #eeeeee;
  width: 148px;
  height: 52px;
  border-radius: 60px;
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #12121260 !important;
  border: none !important;
  &:hover,
  &:focus {
    background: #eeeeee !important;
    color: #12121260 !important;
    border: none !important;
  }
}
.social-icons {
  // width: 80px;
  // height: 52px;
  width: 40px;
  height: 40px;
  border: 1px solid #dddddd;
  border-radius: 60px;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}

.brw-px-1 {
  border-width: 1px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.pointer {
  cursor: pointer;
}

.br-15 {
  border-radius: 15px !important;
}

.br-10 {
  border-radius: 10px !important;
}

.br-100 {
  border-radius: 10px !important;
}

.bg-primary {
  background-color: #22a2ff;
}

.text-white {
  color: #fff;
}

.text-ghost {
  --bs-text-opacity: 1;
  --bs-ghost-rgb: 204, 204, 214;
  color: rgba(var(--bs-ghost-rgb), var(--bs-text-opacity)) !important;
}

.tt-capitalize {
  text-transform: capitalize;
}

.graph-filter-select {
  font-size: 1rem;
  padding: 0 0.5rem 0.25rem 0.5rem;
  border: 0;
  border-bottom: 1px solid #ccc;
}

.carousel-indicators {
  bottom: -30px !important;

  button {
    border-radius: 50%;
    width: 10px !important;
    height: 10px !important;
  }
}

.carousel-control-prev,
.carousel-control-next {
  width: 0px;
}

.carousel-inner {
  padding: 0 25px;
}

// Timeline Goals
.history-tl-container {
  ul.tl {
    margin: 20px 0;
    padding: 0;
    display: inline-block;

    li {
      list-style: none;
      margin: auto;
      margin-left: 50px;
      min-height: 50px;
      border-left: 1px solid #000000;
      padding: 0 0 50px 30px;
      position: relative;

      .item-detail {
        color: #000034;
        font-weight: 600;
        font-size: 12px;
      }
    }

    li:last-child {
      border-left: 0;
    }

    li::before {
      content: url("../images/calendar.svg");
      position: absolute;
      left: -16px;
      top: -5px;
      padding: 4px 4px;
      border: 1px solid #000000;
      border-radius: 500%;
      background: #fff;
      height: 30px;
      width: 30px;
      transition: all 500ms ease-in-out;
    }

    li:hover::before {
      border-color: #258cc7;
      transition: all 1000ms ease-in-out;
    }
  }
}

// Timeline Calendar
.timeline-calendar {
  padding-top: 20px;
  padding-right: 30px;
  padding-left: 30px;
  width: 370px;
  padding-bottom: 100px;
  position: relative;
  background: white;
  display: flex;
  border: 1px solid rgba(224, 224, 224, 1);

  button:nth-child(3) {
    border: none;
    background: none;
    position: absolute;
    right: 30px;
    top: 25px;
    width: 23px;
    height: 28px;
    overflow: hidden;
  }

  button:nth-child(2) {
    border: none;
    background: none;
    position: absolute;
    //width: 23px;
    left: 285px;
    top: 25px;
    height: 28px;
    overflow: hidden;
  }

  .react-datepicker__navigation {
    .react-datepicker__navigation-icon--previous {
      &::before {
        height: 12px;
        width: 12px;
        left: 0;
        border-color: #000;
        border-style: solid;
        border-width: 3px 3px 0 0;
        content: "";
        display: block;
        margin-top: 10px;
        transform: rotate(228deg);
      }
    }

    .react-datepicker__navigation-icon--next {
      &::before {
        height: 12px;
        width: 12px;
        border-color: #000;
        border-style: solid;
        border-width: 3px 3px 0 0;
        content: "";
        display: block;
        margin-top: 10px;
        transform: rotate(45deg);
      }
    }
  }

  .react-datepicker__month-container {
    width: 100%;

    // float:left;
    // padding: 20px;
    .react-datepicker__header {
      background-color: #fff;
      border: 0;

      .react-datepicker__current-month {
        text-align: left;
        margin-left: 0px;
        margin-top: 0px;
        margin-bottom: 35px;
        font-size: 22px;
        font-weight: normal;
      }

      .react-datepicker__day-names {
        display: flex;
        justify-content: center;
        font-size: 12px;

        .react-datepicker__day-name {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
        }
      }
    }

    .react-datepicker__month {
      color: #fff;
      margin: 0;

      // background: #22A2FF;
      .react-datepicker__week {
        display: flex;
        justify-content: center;

        .react-datepicker__day {
          width: 100%;
          border: 1px solid #fff;
          height: 45px;
          color: #fff;
          display: flex;
          margin: 0;
          align-items: center;
          justify-content: center;
          background: #ccccd6;
        }

        .react-datepicker__day--highlighted {
          background-color: #22a2ff;
        }

        .react-datepicker__day--outside-month {
          background-color: #ccccd6;
        }

        // .react-datepicker__day--keyboard-selected {
        //   background-color: #f04858;
        // }
        .react-datepicker__day--selected,
        .react-datepicker__day--in-range {
          background-color: #000034;
        }
      }
    }
  }
}

// Timeline
.timeline-tl-container {
  ul.tl {
    margin: 20px 0;
    padding: 0;
    display: inline-block;

    li {
      list-style: none;
      margin: auto;
      margin-left: 50px;
      min-height: 77px;
      border-left: 1px solid #000;
      padding: 0 0 50px 30px;
      position: relative;

      .item-detail {
        color: #000034;
        font-weight: 600;
        font-size: 12px;
      }
    }

    li::before {
      content: url("../images/calendar.svg");
      position: absolute;
      left: -16px;
      top: -5px;
      padding: 4px 4px;
      border: 1px solid #000;
      border-radius: 500%;
      background: #fff;
      height: 30px;
      width: 30px;
      transition: all 500ms ease-in-out;
    }

    li:hover::before {
      border-color: #258cc7;
      transition: all 1000ms ease-in-out;
    }
  }
}

.custom-radio {
  .form-check-input {
    height: 14px;
    width: 14px;
    &:focus {
      border-color: none !important;
    }
  }

  .form-check-label {
    position: relative !important;
    font-size: 30px;
    cursor: pointer;
  }
}

.custom-table {
  border-collapse: separate;
  border-spacing: 0 5px;

  thead > tr > th {
    color: black;
    font-weight: bold;
    font-size: 12px;
    padding: 5px 8px 0px;
    text-align: left;
  }

  tbody > tr > td {
    padding: 10px;
    font-size: 14px;
  }

  tbody > tr {
    background: #eeeeee59;
    border: 1px solid #eeeeee59;
  }
}

/* Modal */
.button-padding {
  padding: 10px 35px;
}

.modal-para {
  color: $gray-400;
  width: 20rem;
}

.modal-cross {
  position: absolute;
  top: -10px;
  right: -12px;
  width: 38px;
  height: 38px;
  z-index: 9999;
}

.modal.delete-icon {
  .modal-content {
    width: 90% !important;
  }
  .modal-title {
    margin-top: 2.5rem !important;
  }
}
.br-m {
  .modal-content {
    border-radius: 14px;
  }
}

.resend-bt {
  width: 160px !important;
  height: 50px !important;
  margin-top: 50px !important;
}

.ml-5px {
  margin-left: 5px;
}

.cancel-popup {
  max-width: 400px;
  margin-right: auto;
  margin-left: auto;
}

//onBording stepper

.steppers {
  width: 88%;
  div {
    div {
      div {
        a {
          margin-top: 1px !important;
        }

        div {
          display: flex !important;
          justify-content: center;
          align-items: center;
          background-color: $white !important;
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }
  }
}

.active-s-color {
  color: #f5ab38;
}

.defult-color {
  color: #f5ab38;
  background-color: #f5ab38;
}

.input-lable-ob {
  label {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    letter-spacing: 0px !important;
    color: #444444;
  }
}

.entry-mr {
  margin-left: 3px !important;
}

.login_form {
  overflow-y: auto;
  overflow-x: hidden;

  .register-form.label {
    font-size: 14px !important;
  }
}

.step-margins-ob {
  margin-top: 150px;
  margin-left: -25px;
  width: 85%;
}

.step-margins-ob2 {
  margin-top: 200px;
  margin-left: -25px;
  width: 85%;
}

.lable-c {
  color: #444444;
}

.au-header {
  z-index: 99999;
  background-color: white !important;
}
.preview-challenge {
  border: 1px solid #f7f7f7;
  box-shadow: 6px 6px 12px #00000008;
  border-radius: 10px;
  padding: 32px 28px;
}
.show-activity-modal {
  .card-purple {
    background: #8b3b9f;
    border-radius: 14px;
  }
}
.form-check-input:focus {
  border-color: #c7c7c7 !important;
  box-shadow: none !important;
}
.ally-card {
  background: url("../images/ally-card-1.png") no-repeat;
  width: 450px;
  height: 150px;
  background-size: cover;
  background-position: center center;
  border-radius: 9px;
  padding: 20px;
  margin: 0px auto;
  .text {
    width: 315px;
    height: 110px;
    font-size: 18px;
    font-weight: 600;
    color: white;
    text-overflow: ellipsis;
    overflow: hidden;
    overflow-wrap: break-word;
  }
}

.profile-form-grid {
  & > div {
    padding: 0 0.95rem !important;
    // input,
    // select {
    //   font-weight: 400;
    //   font-size: 14px;
    //   line-height: 22px;

    //   letter-spacing: 0.2px;

    //   color: #888888;
    // }
  }
}

.dashboard-squad {
  &:hover {
    transform: scale(1.01);
    transition: transform 300ms ease-in-out;
  }
}

.nsm7Bb-HzV7m-LgbsSe-Bz112c {
  width: 40px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.free-trial-image {
  right: -1rem;
  z-index: 100;
  top: -0.5rem;
}
