.card {
  border: 0;
  box-shadow: $box-shadow-4;

  &-header {
    background-color: #eeeeee;
    // border          : 0;
    // font-size       : 2rem;
    font-weight: 600;
    // color: $gray-900;
    opacity: 0.7;
    border-radius: 6px !important;
    font-size: 18px;
    line-height: 26px;
    color: #121212;
    margin: 10px;
    border: none;
    padding: 14px 10px;
  }
}
