.ff-primary {
  font-family: $primary-font-family !important;
}

// Font Weights
$weight: 100;

@while $weight<=900 {
  .fw-#{$weight} {
    font-weight: #{$weight} !important;
  }

  $weight: $weight + 100;
}

// Font Sizes
$i: 8;

@while $i<=166 {
  .fs-#{$i} {
    font-size: #{$i / $root-font-size}rem !important;
  }

  $i: $i + 1;
}

@media (min-width: $smallScreen) {
  $i: 8;

  @while $i<=166 {
    .fs-sm-#{$i} {
      font-size: #{$i / $root-font-size}rem !important;
    }

    $i: $i + 1;
  }
}

@media (min-width: $mediumScreen) {
  $i: 8;

  @while $i<=166 {
    .fs-md-#{$i} {
      font-size: #{$i / $root-font-size}rem !important;
    }

    $i: $i + 1;
  }
}

@media (min-width: $largeScreen) {
  $i: 8;

  @while $i<=166 {
    .fs-lg-#{$i} {
      font-size: #{$i / $root-font-size}rem !important;
    }

    $i: $i + 1;
  }
}

@media (min-width: $xLargeScreen) {
  $i: 8;

  @while $i<=166 {
    .fs-xl-#{$i} {
      font-size: #{$i / $root-font-size}rem !important;
    }

    $i: $i + 1;
  }
}
