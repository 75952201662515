.box-shadow-none {
  box-shadow: $box-shadow-none !important;
}
.box-shadow {
  box-shadow: $box-shadow !important;
}

// Text Color [Gray]
.text-white {
  color: $white !important;
}
.text-gray-50 {
  color: $gray-50 !important;
}
.text-gray-100 {
  color: $gray-100 !important;
}
.text-gray-200 {
  color: $gray-200 !important;
}
.text-gray-300 {
  color: $gray-300 !important;
}
.text-gray-400 {
  color: $gray-400 !important;
}
.text-gray-500 {
  color: $gray-500 !important;
}
.text-gray-600 {
  color: $gray-600 !important;
}
.text-gray-700 {
  color: $gray-700 !important;
}
.text-gray-800 {
  color: $gray-800 !important;
}
.text-gray-900 {
  color: $gray-900 !important;
}
.text-black {
  color: $black !important;
}
.text-truncate-2 {
  @include line-clamp(2);
}
.text-truncate-1 {
  @include line-clamp(1);
}
.border-bottom {
  border-bottom: $border-bottom !important;
}
.opacity-90 {
  opacity: 0.9;
}

.wh-pre-wrap {
  white-space: pre-wrap;
}
