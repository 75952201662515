@import "~bootstrap/scss/bootstrap";

@media only screen and (max-width: 1200px) {
  html {
    font-size: 14px;
  }
}
@media only screen and (max-width: 768px) {
  html {
    font-size: 13px;
  }
}
body {
  background-color: #fff;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  font-size: $base-font-size;
  font-family: $primary-font-family;
}

#root {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
}

a {
  text-decoration: none;
}

p {
  margin: 0;
}

span.form-error {
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  font-size: 14px;
  font-weight: 500;
}

.loading-indicator {
  position: fixed;
  height: 5px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2000;
  background-color: tint($primary, 75%);
  overflow: hidden;

  .line {
    position: absolute;
    opacity: 0.4;
    background-color: $primary;
    width: 150%;
    height: 5px;
  }

  .subline {
    position: absolute;
    background-color: $primary;
    height: 5px;
  }

  .inc {
    animation: increase 2s infinite;
  }

  .dec {
    animation: decrease 2s 0.5s infinite;
  }

  @keyframes increase {
    from {
      left: -5%;
      width: 5%;
    }

    to {
      left: 130%;
      width: 100%;
    }
  }

  @keyframes decrease {
    from {
      left: -80%;
      width: 80%;
    }

    to {
      left: 110%;
      width: 10%;
    }
  }
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit.fade-exit-active,
.fade-exit.fade-exit-done {
  position: absolute !important;
  z-index: -1;
  width: 0px;
  height: 0px;
  overflow: hidden;

  & > * {
    width: 0px;
    height: 0px;
  }
}

.custom-scroll-bar {
  &::-webkit-scrollbar {
    width: 5px;
    margin-top: 2px;
    height: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $secondary;
    border-radius: 5px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $secondary;
    border-radius: 5px;
  }
}

// .MuiTableCell-root {
//   font-size: 0.85rem !important;
//   font-weight: 500 !important;
//   background: #ffffff;
// }

// .MuiTableCell-footer {
//   border: 0 !important;
// }

.table-toolbar {
  span.applied-filters {
    max-width: 100%;
    overflow-x: auto;
    display: inline-block;

    span.badge {
      font-weight: 500;
      padding: 7px 30px 7px 7px;
      position: relative;
      display: inline-block;
      margin: 0px 5px 4px 0px;

      & > svg {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }
}

.portal,
.filter-drawer {
  &__header {
    box-shadow: $box-shadow;
    backdrop-filter: blur(6px);
    background-color: rgba(250, 250, 250, 0);
  }
}

.daterangepicker {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px !important;
}

// .card.profile {
//   .sb-avatar.profile-picture {
//     border-radius   : 50%;
//     background-color: $gray-50;
//     margin          : -120px auto 40px auto;
//     box-shadow      : $box-shadow-6;
//     overflow        : hidden;
//   }
// }

// table {
// border: 1px solid #EEEEEE;
// border-radius: 10px;
// padding: 10px;
//   &.table {
//     &--details {
//       & > tbody {
//         & > tr {
//           & > th,
//           & > td {
//             padding: 1rem 1rem !important;
//           }

//           & > th {
//             color: $gray-500 !important;
//             font-weight: 500 !important;
//             font-size: 0.85rem !important;
//             vertical-align: middle !important;
//             width: 150px !important;
//             border-radius: 7px 0px 0px 7px !important;
//           }

//           & > td {
//             font-weight: 500 !important;
//             color: $gray-400 !important;
//             vertical-align: middle !important;
//           }

//           & > td {
//             &:last-child {
//               font-weight: 600 !important;
//               color: $gray-800 !important;
//               vertical-align: middle !important;
//               border-radius: 0px 7px 7px 0px !important;
//             }
//           }

//           &:nth-child(odd) {
//             & > * {
//               background-color: $gray-50 !important;
//             }
//           }
//         }
//       }
//     }
//   }
// }

.tab-tr {
  .table-container {
    table {
      thead {
        tr {
          th {
            &:nth-child(2) {
              width: 160px !important;
            }
            &:nth-child(3) {
              width: 140px !important;
            }
          }
        }
      }
    }
  }
}
.table-container {
  border: 1px solid #eeeeee;
  border-radius: 10px;
  padding: 10px;
  overflow: auto;

  table {
    margin-bottom: 0;

    thead {
      background: #20d686;
      opacity: 0.9;
      border-radius: 6px;

      tr {
        th {
          color: #ffffff;
          font-weight: 600;
          font-size: 14px;
          line-height: 30px;
          letter-spacing: 0.2px;
          padding: 8px 20px;
          position: relative;
          text-align: left;

          &::after {
            content: " ";
            width: 1px;
            height: 18px;
            background-color: #ffffff;
            position: absolute;
            opacity: 0.1;
            right: 0;
          }

          &:first-child {
            border-radius: 6px 0px 0px 6px !important;
          }

          &:last-child {
            border-radius: 0px 6px 6px 0px;
            text-align: center;
            &::after {
              display: none;
            }
          }
        }
      }
    }

    tbody {
      background: #f8f8f8;
      opacity: 0.9;
      border-radius: 6px;

      tr {
        td {
          font-weight: 400;
          font-size: 14px;
          line-height: 30px;
          letter-spacing: 0.2px;
          padding: 12px 20px;
          color: #121212;
          position: relative;

          &::after {
            content: " ";
            width: 1px;
            height: 18px;
            background-color: #000;
            opacity: 0.1;
            position: absolute;
            right: 0;
          }

          &:last-child {
            &::after {
              display: none;
            }
          }
        }

        &:last-child {
          td {
            border: none;
          }
        }
      }
    }
    &.active-table {
      thead {
        background: #eeeeee;
        opacity: 0.7;

        tr {
          th {
            color: #121212;
            font-weight: 400;
            padding: 8px 10px;

            &:last-child {
              text-align: start;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            line-height: 22px;
            letter-spacing: 0.2px;
            padding: 8px 10px;
            opacity: 1;
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
}

.layout {
  &--blank {
    background-color: $white;

    .header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      min-height: $header-height;
      background-color: transparent;
      .header-back {
        margin-right: 10px !important;
      }
      .header-ml {
        margin-left: 44px;
      }
    }

    .content {
      height: 100%;
      overflow: auto;
    }
  }

  &--default {
    background-color: $white;
    background-image: url("../images/bodyBottomImage.svg");
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: auto;
    color: $primary-font-color;
    position: relative;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;

    .header {
      box-shadow: none;
      min-height: $header-height;
      backdrop-filter: blur(6px);
      background-color: rgba(250, 250, 250, 0);
      border-bottom: 1px solid rgba(238, 238, 238, 0.5);
      width: calc(100% - #{$sidebar-width});
      left: $sidebar-width;
      transition: all 0.5s ease-in-out;
      .header-back {
        margin-right: 10px !important;
      }
      .header-ml {
        margin-left: 44px;
      }
      &__menu {
        display: none;
      }

      @media (max-width: $xLargeScreen) {
        padding: 1rem;
        left: 0px;
        width: calc(100% - 0px);
        &__menu {
          display: block;
        }
        &-ml {
          margin-left: 20px !important;
        }

        &.open {
          width: calc(100% - 300px);
          left: 300px;
        }
      }
    }

    aside {
      position: fixed;
      width: $sidebar-width;
      left: 0;
      top: $header-height;
      height: calc(100% - #{$header-height});
      top: 0;
      height: 100%;
      background-color: $primary;
      border-radius: 0px 10px 10px 0px;
      transition: all 0.5s ease-in-out;

      &.open {
        transform: translateX(0);
        width: 300px;
      }

      @media (max-width: $xLargeScreen) {
        transform: translateX(-100%);
        // width: 0px;
        position: fixed;
        z-index: 10000;

        .logo-text {
          display: none;
        }
      }

      .nav {
        a {
          &.nav-item {
            color: $white !important;
            background-color: $primary !important;
            transition: all 0.3s ease;
            font-weight: 300;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-top: 0.375rem !important;
            box-shadow: none !important;
            border-bottom: 1px solid #ffffff15;

            &:hover {
              border-bottom-right-radius: 10px !important;
              border-top-right-radius: 10px !important;

              background-color: shade($primary, 15%) !important;
            }

            &.active {
              border-bottom-right-radius: 10px !important;
              border-top-right-radius: 10px !important;

              background-color: shade($primary, 15%) !important;
              color: $white !important;

              &:hover {
                background-color: shade($primary, 15%) !important;
                color: $white !important;
              }

              &::after {
                content: "";
                width: 4px;
                height: 28px;
                background-color: white;
                position: absolute;
                right: 15px;
                border-radius: 50px;

                // @media (max-width: $largeScreen-1) {
                //   display: none;
                // }
              }
            }
          }
        }
      }
    }

    main {
      margin-top: 1rem;
      margin-left: $sidebar-width;
      width: calc(100% - #{$sidebar-width});
      min-height: calc(100% - #{$header-height});

      @media (max-width: $xLargeScreen) {
        margin-left: 0px;
        width: calc(100% - 0px);
      }
    }
  }
}

//Library page UI

.library-main {
  .library-btn {
    padding: 13px 40px !important;
    height: 46px;
    background: #309dec;
    border-radius: 60px;
    border: none;
    outline: none;
    box-shadow: none;

    .btn-para {
      height: 20px;
      font-family: "Source Sans 3";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height */

      letter-spacing: 0.2px;

      color: #ffffff;
    }
  }

  .library-btn1 {
    padding: 13px 40px !important;
    height: 46px;
    border: none;
    outline: none;
    box-shadow: none;
    background: #eeeeee;
    border-radius: 60px;

    .btn-para1 {
      height: 20px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.2px;
      color: #121212;
      opacity: 0.5;
      margin: auto;
    }
  }
}
.squad-main {
  .squad-btn {
    padding: 13px 40px !important;
    height: 46px;
    background: #309dec;
    border-radius: 60px;
    border: none;
    outline: none;
    box-shadow: none;

    .btn-para {
      height: 20px;
      font-family: "Source Sans 3";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height */

      letter-spacing: 0.2px;

      color: #ffffff;
    }
  }

  .squad-btn1 {
    padding: 13px 40px !important;
    height: 46px;
    border: none;
    outline: none;
    box-shadow: none;
    background: #eeeeee;
    border-radius: 60px;

    .btn-para1 {
      height: 20px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.2px;
      color: #121212;
      opacity: 0.5;
      margin: auto;
    }
  }
}

.library-main1 {
  .library-text {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #121212;
  }
}

.library-container {
  .library-image {
    width: 100px;
    // height: 100px;

    img {
      width: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  .library-detail {
    .library-heading {
      font-family: "Source Sans 3";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #121212;
      opacity: 0.9;
    }

    .library-results {
      .library-win {
        // width: 68px;
        height: 20px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #121212;

        .library-response {
          width: 116px;
          height: 16px;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          /* identical to box height, or 114% */

          color: #121212;

          opacity: 0.5;
        }
      }
    }
  }
}

.library-text-duration {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #121212;
}

.library-days {
  height: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #121212;
  opacity: 0.5;
}

.library-challenge {
  width: 130px;
  height: 22px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #f99f22;
}

//library UI tab
.library-new-challenge {
  .overview-library {
    .overview-btn {
      width: 148px;
      height: 48px;
      background: #eeeeee;
      border-radius: 30px;
      border: none;
      outline: none;
      box-shadow: none;

      .overview-text {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #121212;
        opacity: 0.5;
      }
    }
  }
}

// conditional btn css
.overview-btn1 {
  width: 148px;
  height: 48px;
  background: #309dec;
  border-radius: 30px;
  border: none;
  outline: none;
  box-shadow: none;
}

.overview-text1 {
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

// .abc path{
//   fill: #000 !important;
// }

//Overview tab
.library-uploader {
  width: 112px;
  height: 112px;
  background: #ffffff;
  border: 1px dashed rgba(114, 114, 114, 0.3);
  border-radius: 4px;
  cursor: pointer;
  margin-top: 40px;
  border-radius: 50%;
  label {
    background: transparent !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: none !important;

    .upload-img {
      display: block;
      margin: auto;
      margin-top: 20px !important;
    }

    .upload-text {
      font-family: "Mulish", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 24px;
      color: #333333;
      margin: auto;
      .library-browse {
        color: #309dec;
        text-decoration: underline;
      }
    }

    .library-format {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #676767;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.height-160 {
  height: 160px !important;
}
.outer-container {
  .inner-container {
    .overview-container {
      .overview-input {
        width: 460px;
        height: 50px;
        border: 1px solid #ebebeb;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
      }

      .overview-select {
        width: 460px;
        height: 50px;
        border: 1px solid #ebebeb;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        padding: 15px;
      }
    }

    .overview-details {
      .overview-textarea {
        .textarea-label {
          width: 70px;
          height: 20px;
          font-family: "Source Sans 3";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #444444;
          transform: none !important;
        }
        textarea {
          resize: none;
          color: #888888;

          &::placeholder {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.upload-input {
  display: none;
}

//ovelap image
.overview-symbol {
  position: relative;

  imgs {
    width: 80%;
  }
}

.overview-camera {
  position: absolute;
  right: -5%;
  bottom: 10%;
}

//overview btn
.overview-btn {
  width: 180px;
  height: 52px;
  background: #309dec;
  border-radius: 50px;
  border: none;
  outline: none;
  box-shadow: none;
}

.btn-text {
  width: 33px;
  height: 22px;
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
}

.btn-text-c {
  height: 22px;
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
}

//Activities tab

// .activity-conatiner {
//   .activity-main {
//     .activity-details {
//       .activity-quest {
//         width: 53px;
//         height: 26px;
//         font-family: "Source Sans 3";
//         font-style: normal;
//         font-weight: 600;
//         font-size: 18px;
//         line-height: 26px;
//         color: #121212;
//       }
//     }
//   }
// }

.activity-quest {
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #121212;
}

.activity-number {
  width: 26px;
  height: 23px;
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #121212;
  opacity: 0.5;
}
.activity-card {
  background: #ffffff;
  border: 1px solid #f1f1f1;
  box-shadow: 4px 6px 9px rgba(0, 0, 0, 0.003%);
  border-radius: 10px;
  margin-top: 10px;
}
.activity-add {
  width: 120px;
  height: 126px;
  background: #ffffff;
  border: 1px dashed rgba(18, 18, 18, 0.2);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: dashed 4px #000;
  border-image-source: url("../images/border-dashed.png");
  border-image-slice: 3;
  border-image-repeat: round;
  opacity: 0.7;
}

.activity-guy-text {
  height: 26px;
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #121212;
}

.activity-guy-number {
  width: 26px;
  height: 23px;
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #121212;
  opacity: 0.5;
}

.activity-btn {
  width: 180px;
  height: 52px;
  background: #309dec;
  border-radius: 50px;
  border: none;
  outline: none;
  box-shadow: none;
}

.activity-btn-text {
  width: 32px;
  height: 22px;
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
}

.activity-next {
  display: flex;
  justify-content: center;
}

//library resources

.library-btn {
  cursor: pointer;

  .library-text1 {
    width: 59px;
    height: 22px;
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #121212;
    opacity: 0.5;
  }
}

.custom-btn {
  cursor: pointer;

  .custom-text {
    width: 59px;
    height: 22px;
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #121212;
    opacity: 0.5;
  }
}

//Conditional
.library-text2 {
  width: 56px;
  height: 22px;
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #f5ab38;
}

.custom-text1 {
  width: 59px;
  height: 22px;
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #f5ab38;
}

//Library tab UI

// Custom Tab UI

.custom-upload {
  width: 348px;
  height: 180px;
  background: #ffffff;
  border: 1px dashed rgba(114, 114, 114, 0.3);
  border-radius: 4px;
  margin: auto;
}

.custom-heading {
  width: 100%;
  height: 100%;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #0f0f0f;
}

.custom-input {
  border: 1px solid #ebebeb;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}

.custom-textarea {
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
}

.custom-btn1 {
  width: 180px;
  height: 52px;
  background: #309dec;
  border-radius: 50px;
  border: none;
  outline: none;
  box-shadow: none;
}

.custom-btn-text {
  width: 51px;
  height: 22px;
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  margin: auto;
}

.custom-browse {
  color: #4a60ab;
}

.custom-support {
  width: 311px;
  height: 18px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  color: #676767;
}

//Advance tab

.advance-power {
  width: 90px;
  height: 26px;
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #121212;
}

.advance-guy-text {
  width: 97px;
  height: 26px;
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #121212;
}

//Preview challenge

.preview-host-btn {
  width: 142px;
  height: 46px;
  background: #309dec;
  border-radius: 60px;
  border: none;
}

.host-text {
  height: 20px;
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #ffffff;
}

.preview-save-btn {
  width: 142px;
  height: 46px;
  background: #eeeeee;
  border-radius: 60px;
  border: none;
}

.save-text {
  width: 110px;
  height: 20px;
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #121212;
  opacity: 0.5;
}

//Host challenge
.host-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .host-symbol {
    display: flex;
    align-items: center;

    .host-text {
      .host-heading {
        height: 28px;
        font-family: "Source Sans 3";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: #121212;
        margin-left: 10px;
      }
    }
  }

  .host-review {
    .host-btn {
      width: 142px;
      height: 46px;
      background: #f5ab38;
      border-radius: 60px;
      border: none;

      .host-btn-text {
        width: 46px;
        height: 20px;
        font-family: "Source Sans 3";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #ffffff;
      }
    }
  }
}

.host-squad {
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #121212;
}

.host-name {
  width: 86px;
  height: 23px;
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #121212;
  opacity: 0.9;
}

.host-outer-container {
  display: flex;
}

.host-inner-container {
  width: 100%;
}

.host-divide {
  height: 0px;
  opacity: 0.04;
  border: 1px solid #000000;
  margin-top: -1px;
}

.host-save-btn {
  width: 180px;
  height: 52px;
  background: #309dec;
  border-radius: 50px;
  border: none;
  outline: none;
}

.host-btn-text {
  height: 22px;
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
}

// .host-division{
//   width: 56%;
// }
.host-btns {
  margin-top: 10%;
}

.host-date {
  width: 228px;
  height: 50px;
  border: 1px solid #ebebeb;
  border-radius: 6px;
  background: url("../images/calendar.svg") no-repeat;
  background-position: 190px center;
  width: 228px;
}

.host-dropDown-date {
  width: 150px;
  height: 40px;
  border: 1px solid #ebebeb;
  border-radius: 6px;
  margin-right: 20px;
}

.host-start-date {
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
}

.select-squad {
  background: #ffffff;
  border: 1px solid #f9f9f9;
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
}

//Save Challenge

.save-challenge-squad {
  width: 55px;
  height: 28px;
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #121212;
}

.save-squad-start {
  display: flex;
  align-items: center;
}

.save-squad {
  width: 45%;
}

.save-invite-btn {
  width: 192px;
  height: 46px;
  background: #f5ab38;
  background: url("../images/invitation.png") no-repeat;
  background-size: contain;
  border-radius: 60px;
  border: none;
  outline: none;
  margin-left: auto;
}

.save-invite-text {
  width: 130px;
  height: 20px;
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  letter-spacing: 0.2px;
  color: #ffffff;
}

//Dropdown
.save-invite-btn::after {
  display: none;
}

.dropdown-btn {
  margin-left: auto;
}

.drop-menu-invite {
  width: 192px;
  height: auto;
  background: #ffffff;
  border: 1px solid #f1f1f1;
  box-shadow: 4px 6px 9px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  padding: 0 !important;
}

.drop-menu-text {
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #121212;
  padding: 15px 20px;
}

.drop-menu-text-disable {
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: $gray-300;
  padding: 15px 20px;
}

.drop-menu-hr {
  width: 168px;
  height: 0px;
  left: 1212px;
  top: 193px;
  opacity: 0.08;
  margin: auto;
}

//Library tab

.library-text-heading {
  width: 66px;
  height: 66px;
  background: #f99f22;
  border-radius: 50%;
}

.library-text-name {
  width: 35px;
  height: 43px;
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 43px;
  color: #ffffff;
  opacity: 0.9;
}

//Active Challenges

.active-container {
  display: flex;
  align-items: center;

  .active-btn {
    .active-state1 {
      width: 166px;
      height: 46px;
      background: #309dec;
      border-radius: 60px;
      border: none;

      .active-text {
        width: 110px;
        height: 20px;
        font-family: "Source Sans 3";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #ffffff;
      }
    }
  }

  .active-btn1 {
    .active-state2 {
      width: 196px;
      height: 46px;
      background: #eeeeee;
      border-radius: 60px;
      border: none;

      .active-text1 {
        width: 140px;
        height: 20px;
        font-family: "Source Sans 3";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #121212;
        opacity: 0.5;
      }
    }
  }
}

//Conditional
.active-state3 {
  width: 166px;
  height: 46px;
  background: #eeeeee;
  border-radius: 60px;
  border: none;
}

.active-text2 {
  width: 110px;
  height: 20px;
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #121212;
  opacity: 0.5;
}

.active-state4 {
  width: 196px;
  height: 46px;
  background: #309dec;
  border-radius: 60px;
  border: none;
}

.active-text3 {
  width: 140px;
  height: 20px;
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #ffffff;
}

//Active challnege details

.player-detail {
  width: 60px;
  height: 20px;
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #121212;
}

//Active Grade CHallenge

.grade-heading {
  width: 152px;
  height: 28px;
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #121212;
}

.img {
  border-radius: 50px;
}

.curser-pointer {
  cursor: pointer;
}

/* subscribe */
.list-group-item {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 2px;
  text-align: left;
  opacity: 0.5;
}

.main-Card-text {
  background: transparent !important;
  border-bottom: 0.5px solid $primary !important;
  padding: 22px 0px !important;
  &.subscription-heading {
    border-radius: 0px !important;
  }
}

.payment-class {
  width: 65rem;
  padding: 3rem;
  box-shadow: 5px 10px $gray-50;
  border-radius: 10px;
  border: $gray-50;
}

.Payment-button {
  background-color: $primary;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  border-radius: 10px;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

/* tranparent background */

.bg-transparent {
  background-color: transparent;
}

/* Heading after line */
.bg-color-s {
  background-color: #f99f22;
  display: inline-block;
  min-height: 3px;
  min-width: 2rem;
  -webkit-transform: translateY(-0.45rem);
  transform: translateY(-0.45rem);
}

.check {
  .form-check-label {
    position: relative !important;
    font-size: 18px;
    cursor: pointer;
    color: $check-label-color;
    margin-top: 8px;
  }
}

.divider {
  height: 0px;
  opacity: 0.08;
  margin-top: -1px;
}

.textSub {
  color: $gray-400;
}

.textboxBorder {
  border: 1px solid $text-border;
}

.card-margins-info {
  margin-top: -65px !important;
}
.margin-info-div {
  margin-left: 100px !important;
}

.form-group-checkbox {
  display: block;
  margin-bottom: 15px;
  margin-top: 5px;
}

.form-group-checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group-checkbox label {
  position: relative !important;
  cursor: pointer;
  font-size: 18px !important;
  font-weight: 400;
  color: #888888;
}

.form-group-checkbox label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #4a60ab;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 6px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.form-group-checkbox input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 14px;
  width: 4px;
  height: 9px;
  border: solid #4a60ab;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.delete-btn-cl {
  color: #808080;
  font-size: 16px;
}
