.main-analytics {
  .analytics {
    margin-left: 11px;
    margin-top: 20px;
  }

  .tabs {
    margin-top: 24px;
    height: 68px;

    &.col-sm-3 {
      width: 23% !important;
      margin-right: 33.5px;
    }
  }

  .tab {
    height: 68px;
    margin-top: 24px;

    &.col-sm-3 {
      width: 23% !important;
    }
  }

  .tab-spacing {
    width: 100%;
    text-align: center;
    padding-left: 16px;
  }

  .pie-chart-analytics {
    margin-top: 28px;
    border-radius: 10px;
    box-shadow: $box-shadow-card !important;
    flex-direction: column;
    border: 1px solid $card-border;
    margin-right: 0px;

    &.col-sm-8 {
      flex: 0 0 auto;
      width: 62.66667%;
      margin-left: 45px;
    }
  }

  .pie-title {
    padding: 14px 16px;
    background: $gray-200;
    border-radius: 6px;
    margin-bottom: 35px;
  }

  .first-div-score {
    width: 13rem;
  }

  .stripe {
    // width: 30rem;
  }

  .header-analytics {
    box-shadow: 0px 7px 8px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
  }

  .bar {
    margin-top: 22px;
    position: relative;
    &::after {
      content: " ";
      position: absolute;
      background: url("../images/border-dotted.png") no-repeat;
      width: 62%;
      height: 62%;
      top: 50%;
      left: 50%;
      transform: translate(-53%, -51%);
      background-size: contain;
    }
  }

  .right-no {
    border-right: 2px solid rgba(0, 0, 0, 0.1);
    padding-right: 20px;
    height: 32px;
  }

  .first-div-strength {
    margin-top: -20px;
  }

  .hr-divider {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .pie-bar {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    font-weight: 600 !important;
    line-height: 28px !important;
    color: $black;
    opacity: 0.6;
    margin-top: -10px;
  }

  .bar-title {
    margin-top: 10px;
  }
}

.piechart-container {
  position: relative;

  .resilience-container {
    position: absolute;
    top: 44%;
    left: 52%;
    transform: translate(-56%, -48%);
    text-align: center;
  }

  .data-chart {
    li {
      font-weight: 400;
      font-size: 12.2457px;
      line-height: 17px;
      color: #666666;
      position: relative;
      display: flex;
      align-items: center;

      &::before {
        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        left: -15px;
        border-radius: 50%;
      }
    }
    li:nth-child(1) {
      &:before {
        background-color: #cf4476;
      }
    }
    li:nth-child(2) {
      &:before {
        background-color: #f9a623;
      }
    }
    li:nth-child(3) {
      &:before {
        background-color: #2844a3;
      }
    }
    li:nth-child(4) {
      &:before {
        background-color: #b9d435;
      }
    }
  }

  .text-pie {
    margin-top: -10px;
    text-align: center;
  }

  .main-title {
    font-weight: bolder;
  }

  .card-title {
    color: #121212;
  }

  h5 {
    line-height: 0.5rem;
  }

  p {
    width: 3.5rem;
  }
}
