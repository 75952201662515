#page {
  &--login,
  &--forgot-password,
  &--signup,
  &--reset-password,
  &--onboarding-personal-details {
    height: 100%;
    // overflow-y: auto;

    .page--wrapper {
      @media (min-width: $xLargeScreen) {
        grid-template-columns: 50% auto;
      }

      .login {
        &_img {
          height: 100%;
          background-color: $white;
          background-image: url(../images/login-img.png);
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
        }
      }
    }
  }

  &--subscribe {
    height: 100%;
    overflow-y: auto;
    background-color: $white;
    background-image: url(../images/subscribe-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    .plan--details {
      grid-template-columns: 360px auto;
      gap: 30px;
      max-width: 1024px;

      .host-offerings {
        ul.offerings {
          li {
            list-style: none;
            position: relative;
            background-image: url(../images/bullet-point.svg);
            background-repeat: no-repeat;
            background-size: 14px;
            background-position: 0px 10px;
            max-width: unset !important;
          }
          li.free-trial {
            background-image: url(../images/bullet-point-1.svg);
          }
        }
      }

      .subscription-plans {
        .subscription-plan {
          border: 1px solid rgba(48, 157, 236, 0) !important;
          transition: border-color 0.3s ease-in-out;
          cursor: pointer;

          &:hover {
            border: 1px solid rgba(48, 157, 236, 1) !important;
          }

          &:last-child {
            margin-bottom: 0 !important;
          }

          .select-op {
            opacity: 5;
          }

          .card-body {
            grid-template-columns: 250px 1fr;
            gap: 20px;

            .plan-title {
              background-color: $gray-100;
            }

            .btn {
              border-width: 1px !important;
              font-weight: 400 !important;
              font-size: 14px !important;
            }
          }
        }
      }
    }
  }

  &--account {
    .profile {
      .sb-avatar {
        margin: -120px auto 40px auto;
        box-shadow: $box-shadow-6;
      }
    }

    .host-offerings {
      .card-body {
        position: relative;
        padding-bottom: 0px !important;
      }
      border: 1px solid #f9f9f9;
      box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.03);
      border-radius: 8px;
      margin-bottom: 1.3rem;
      padding-left: 0.5rem;
      padding-top: 0.5rem !important;
    }
  }
}

.ob-lables {
  .form-check {
    .form-check-label {
      font-size: 14px !important;
      transform: translate3d(0.5rem, -0.3rem, 0) scale(1);
      font-weight: 400;
      line-height: 15px;
      margin-top: 6px !important;
      padding-top: 8px !important;
    }
    .form-check-input {
      width: 24px !important;
      height: 24px !important;
      padding: 0 !important;
      cursor: pointer;
    }
  }
}
