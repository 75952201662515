@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

// utitity to make width custom classes
@for $index from 1 to 10 {
  .w-#{$index} {
    width: (10% * $index) !important;
  }

  .h-#{$index} {
    height: (10% * $index) !important;
  }
}
@mixin line-clamp($number) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $number;
  -webkit-box-orient: vertical;
}
